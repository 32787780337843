import React, { useState, useEffect, useContext } from "react";
import person from '@img/person.webp';
import small from '@img/small-pet.webp';
import medium from '@img/medium-pet.webp';
import large from '@img/large-pet.webp';
import xlarge from '@img/xlarge-pet.webp';
import Loading from '../components/Loading';
import * as bootstrap from 'bootstrap';
import ToastModal from "../components/ToastModal";
import $ from 'jquery';
import UserContext from "../context/useLoginContext";
import Select from 'react-select';
import usePetTypes from '../hooks/usePetTypes';
import { getProfile, updateProfile } from '../api/index';
import ModalResponse from "../components/ModalResponse";
import '@styles/Profile.scss';

const Profile = () => {
    const [loading, setLoading] = useState(true);
    const [messageToast, setMessageToast] = useState(null);
    const [message, setMessage] = useState(null);
    const { user } = useContext(UserContext);
    const [imageUrl, setImageUrl] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [size, setSize] = useState(null);
    const [typePets, setTypePets] = useState(null);
    const [isdashPage, setIsdashPage] = useState(true);
    const [profile, setProfile] = useState(null);
    const [userName, setUserName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [petName, setPetName] = useState(null);
    const [petBirthDay, setPetBirthDay] = useState(null);
    const [typePet, setTypePet] = useState(null);
    const [defaultValueSelect, setDefaultValueSelect] = useState(null);

    const arraySize = [
        {img: small, text: "Pequeño", keySelect:'s'},
        {img: medium, text: "Mediano",  keySelect:'m'},
        {img: large, text: "Grande",  keySelect:'l'},
        {img: xlarge, text: "Extra Grande",  keySelect:'xl'},
    ]

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto(file);
            setImageUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const processDataSearch = async() =>{
        setLoading(true);
        const [{typePets}, profileResponse] = await Promise.all([
            usePetTypes(),
            getProfile()
        ]);
        setTypePets(typePets);
        if(profileResponse.status !== "fail"){
            const {profile} = profileResponse.data;
            const {pets} = profile
            setProfile(profile)
            if(pets?.length){
                setSize(pets[0]?.size)
                setPetBirthDay(pets[0]?.birthDay)
                setDefaultValueSelect({value:pets[0]?.typePet?.value, label: pets[0]?.typePet?.name})
            }
        }else{
            setMessageToast("¡Lo sentimos!, hubo un error al intentar obtener la información del usuario.")
            const toastEl = $('#liveToast')[0];
            if (toastEl) {
                let toast = new bootstrap.Toast(toastEl, { delay: 6000 });
                toast.show();
                navigate('/');
            }
        }
        setLoading(false);
        setIsdashPage(true)
    }

    const fetchData = async () => {
        try {
            if (user) {
                await processDataSearch();
            }
        } catch (error) {
            console.error("Error fetching store when user is logged:", error);
            setMessageToast("¡Lo sentimos! tuvimos problemas al solicitar la informaciópn del usuario.")
            if (messageToast) {
                const toastEl = $('#liveToast')[0];
                if (toastEl) {
                    let toast = new bootstrap.Toast(toastEl, { delay: 5000 });
                    toast.show();
                }
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [user]);

    const handleChangeUserName = (event) => { setUserName(event.target.value) };

    const handleChangeLastName = (event) => { setLastName(event.target.value) };

    const handleChangePhone = (event) => { setPhone(event.target.value) };

    const handleChangePetName = (event) => { setPetName(event.target.value) };

    const handleChangeBirthDay = (event) => { setPetBirthDay(event.target.value) };

    const handleChangePetType = async(selectedOption) => {
        setDefaultValueSelect(selectedOption)
        setTypePet(selectedOption.value)
    };

    const updateProfileSubmit = async(event) => {
        event.preventDefault();
        let formData = new FormData();
        if(photo){
            formData.append('photo', photo);
        }
        if(userName !== profile?.firstName){
            formData.append('name', userName);
        }
        if(lastName !== profile?.LastName){
            formData.append('lastname', lastName);
        }
        if(phone !== profile?.phone){
            formData.append('phone', phone);
        }
        if(petName && petName !== profile?.pets[0]?.name){
            formData.append('petname', petName);
        }
        if(petBirthDay && petBirthDay !== profile?.pets[0]?.birthDay){
            formData.append('birthDay', petBirthDay);
        }
        if(size){
            formData.append('size', size);
        }
        if(typePet && typePet !== profile?.pets[0]?.typePet.value){
            formData.append('petType', typePet);
        }

        setIsdashPage(false)
        setLoading(true);
        const response = await updateProfile(formData)
        setLoading(false);
        setIsdashPage(true)

        if(response.status !== "fail"){
            setMessage("Hemos guardado tu información y la de tu mascota con éxito")
            $('#response-modal').modal('show');
        }else{
            setMessageToast("¡Lo sentimos!, hubo un error al intentar actualizar la información.")
            const toastEl = $('#liveToast')[0];
            if (toastEl) {
                let toast = new bootstrap.Toast(toastEl, { delay: 6000 });
                toast.show();
                navigate('/');
            }
        }
    };


    return (
        <div className="Profile">
            <div className='Content'>
                <form id="form-profile">
                    <div className="title">Mi cuenta</div>
                    <div className="subtitle">Tus datos</div>
                    <div className="content-profile">
                        <div className="label-dash">Foto</div>
                        <div className="foto">
                            <img
                                src={imageUrl || user && user.photo}
                                alt="firulife-user"
                                onError={(e) => { e.target.onerror = null; e.target.src = person; }}
                            />
                            <label htmlFor="file-upload" className="custom-file-upload">
                                Cambiar
                            </label>
                            <input name="chagePhotoProfile" id="file-upload" type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <label className="label-dash" htmlFor="validationDefaultUsername">Nombre(s)</label>
                                <div className="input">
                                    <input type="text"
                                        defaultValue={profile?.firstName}
                                        onChange={handleChangeUserName}
                                        id="validationDefaultUsername"
                                        aria-describedby="inputGroupPrepend2"
                                        name="firstNameProfile"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label className="label-dash" htmlFor="validationDefaultLastname">Apellidos</label>
                                <div className="input">
                                    <input type="text"
                                        defaultValue={profile?.LastName}
                                        onChange={handleChangeLastName}
                                        id="validationDefaultLastname"
                                        aria-describedby="inputGroupPrepend2"
                                        name="LastNameProfile"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="label-dash">Celular</div>
                                <div className="input">
                                    <input type="number"
                                        defaultValue={profile?.phone}
                                        onChange={handleChangePhone}
                                        name="phoneProfile"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="subtitle">Los datos de tu mascota</div>
                    <div className="container-pet">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="label-dash">Nombre de tu mascota</div>
                                <div className="input">
                                    <input type="text"
                                        defaultValue={profile?.pets?.length > 0 && profile?.pets[0]?.name ? profile?.pets[0]?.name : '' }
                                        onChange={handleChangePetName}
                                        name="petNameProfile"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="label-dash">Tipo de mascota</div>
                                <div className="input"><Select
                                    className="select-input"
                                    classNamePrefix="select-input"
                                    value={defaultValueSelect}
                                    defaultValue={defaultValueSelect}
                                    options={typePets}
                                    onChange={handleChangePetType}
                                    placeholder="Selecciona el tipo de mascota"
                                    name="petTypeProfile"
                                /></div>
                            </div>
                            <div className="col-md-4">
                                <div className="label-dash">Fecha de nacimiento</div>
                                <div className="input">
                                    <input type="date"
                                        value={petBirthDay || new Date().toISOString().split('T')[0]}
                                        onChange={handleChangeBirthDay}
                                        name="petBirthDayProfile"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="label-dash">Tamaño de tu mascota</div>
                            {arraySize.map((item, index) => (
                                    <div className={size === item.keySelect ?"size-pet border-blue" : "size-pet"} key={index} onClick={()=>setSize(item.keySelect)}>
                                        <div className="label-size">
                                            <img
                                                src={item.img}
                                                alt="firulife-pet"
                                            />
                                        </div>
                                        <div className="text-size">{item.text}</div>
                                    </div>
                            ))}
                        </div>
                    </div> */}
                    <button className="submit-profile" aria-label="save-profile" type="button" onClick={updateProfileSubmit}>Guardar</button>
                </form>

            </div>
            <ModalResponse message={message} url={"/dashboard/profile"}/>
            <ToastModal message={messageToast}/>
            {
                loading && <Loading  isdashPage={isdashPage}/>
            }
        </div>
    );
}

export default Profile;
